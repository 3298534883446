import React from "react"

const ContactUsCard = () => {
  return (
    <div className="boxed">
      <div className="bg-white shadow rounded">
        <div className="image-block-wrapper">
          <div className="image-block col-lg-6 p-0">
            <div className="google-map map-full">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.489880875623!2d-0.14429318389712162!3d51.54091557964065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ae1f6b6e8f5%3A0x60718a9775fb0dd9!2s31-35%20Kentish%20Town%20Rd%2C%20Camden%20Town%2C%20London%20NW1%208NL!5e0!3m2!1sen!2suk!4v1585744659473!5m2!1sen!2suk"
                style={{ width: "100%", height: "100%", border: 0 }}
                allowFullScreen
                title="Google Map"
              ></iframe>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6">
                <div className="box d-flex">
                  <div className="align-self-center">
                    <h3>Contact Information</h3>
                    <p>We're based in the heart of Camden Town in London.</p>
                    <ul className="icon-list">
                      <li>
                        <i className="icofont-location-arrow" />
                        Camden Town, London, United Kingdom
                      </li>
                      <li>
                        <i className="icofont-envelope" />
                        <a href="mailto:hi@edamame.studio" className="nocolor">
                          hi@edamame.studio
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUsCard
