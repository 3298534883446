import React from "react"

const ContactUsForm = () => {
  return (
    <div className="row">
      <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
        <h2 className="section-title text-center">Drop Us a Line</h2>
        <div className="space30" />
        <form
          id="contact-form"
          className="fields-white"
          method="POST"
          data-netlify="true"
        >
          {/* For netlify */}
          <p className="d-none">
            <label>
              Don’t fill this out if you're human: <input name="bot-field" />
            </label>
          </p>
          <input type="hidden" name="form-name" value="contact-form" />
          {/*  */}
          <div className="messages" />
          <div className="controls">
            <div className="form-row">
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    id="form_name"
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="First, Last Name*"
                    required="required"
                    data-error="Name is required."
                  />
                  <div className="help-block with-errors" />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    id="form_email"
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email *"
                    required="required"
                    data-error="Valid email is required."
                  />
                  <div className="help-block with-errors" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    id="form_phone"
                    type="tel"
                    name="phone"
                    className="form-control"
                    placeholder="Phone"
                  />
                  <div className="help-block with-errors" />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <div className="form-group">
                  <textarea
                    id="form_message"
                    name="message"
                    className="form-control"
                    placeholder="Message *"
                    rows={4}
                    required="required"
                    data-error="Message is required."
                    defaultValue={""}
                  />
                  <div className="help-block with-errors" />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12">
                  <p className="text-muted">
                    <strong>*</strong> These fields are required.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <input
                  type="submit"
                  className="btn btn-send float-right"
                  value="Send message"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactUsForm
