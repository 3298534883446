// @ts-check
import React from "react"

import Layout from "../components/layout/Layout"
import ContactUsCard from "../partials/ContactUsCard"
import ContactUsForm from "../partials/ContactUsForm"
import ImageHeader from "../components/ImageHeader"

const ContactPage = () => {
  return (
    <Layout transparentHeader title="Contact">
      <div>
        <ImageHeader
          imgSrc="/images/bg/contact.jpg"
          title="Contact Us"
          lead="We'd love to know more about your project"
        />
        <div className="wrapper light-wrapper">
          <div className="container inner">
            <ContactUsCard />
            <div className="space90" />
            <ContactUsForm />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
